import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Ville will reopen tomorrow, June 1st, for class at 6:00am!
 Please check the schedule page for updated class times.  Members will
need to sign up for class ahead of time on the link we emailed you.  We
hope to get back to our normal class schedule asap and will keep you
updated on any changes.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      